import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Tab,
  Tabs,
  Stack,
  Button,
  Box,
  Link,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import EventNoteIcon from "@mui/icons-material/EventNote";
import TimeIcon from "@mui/icons-material/AccessTime";
import SendIcon from "@mui/icons-material/Send";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HelpIcon from "@mui/icons-material/Help";
import HotelIcon from "@mui/icons-material/Hotel";

const StyledImage = styled("img")({
  width: "100%",
  maxWidth: "600px",
  objectFit: "cover",
});

let images = [];
for (let i = 1; i <= 111; i++) {
  images.push(i + ".jpg");
}

images.sort((a, b) => Math.random() - 0.5);

const StyledHr = styled(Box)({
  height: "1px",
  width: "100%",
  border: "none",
  marginTop: "2rem",
  marginBottom: "2rem",
  backgroundImage: "linear-gradient(to right, #333, #ccc, #333)",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#404040", // dark gray
    },
    secondary: {
      main: "#9E1E38", // red
    },
    background: {
      default: "#f5f5f5", // light gray
    },
    text: {
      primary: "#212121", // dark gray
      secondary: "#757575", // gray
    },
  },
  typography: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
    h1: {
      fontWeight: "bold",
      fontSize: "3rem",
      letterSpacing: "0.05rem",
      lineHeight: "4rem",
      fontFamily: "Alura",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "2rem",
      letterSpacing: "0.05rem",
      lineHeight: "3rem",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      letterSpacing: "0.05rem",
      lineHeight: "2rem",
    },
    body1: {
      fontSize: "1.2rem",
      letterSpacing: "0.02rem",
      lineHeight: "1.8rem",
    },
    body2: {
      fontSize: "1rem",
      letterSpacing: "0.02rem",
      lineHeight: "1.5rem",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    width: "100%",
  },
  tabs: {
    flexGrow: 1,
  },
  iframeContainer: {
    width: "100%", // Set the width of the iframe container to 100%
    height: "100%", // Set the height of the iframe container to 100%
    overflow: "hidden", // Hide any content that overflows the container
  },
  quote: {
    backgroundColor: "#9E1E38", // theme.palette.secondary.main,
    padding: "1em",
    borderRadius: "10px",
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
  },
  quoteText: {
    color: "#eeeeee", // theme.palette.text.primary,
    fontStyle: "italic",
    fontSize: "1rem",
    letterSpacing: "0.02rem",
    lineHeight: "1.8rem",

    // color: theme.palette.secondary.contrastText,
  },
}));

function App() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("home");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    const section = document.getElementById(newValue);
    section.scrollIntoView({ behavior: "smooth" });
  };

  const handleClick = () => {
    const section = document.getElementById("rsvp");
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Container maxWidth="md">
          {/* <Stack alignItems="center" sx={{ marginBottom: "3em" }}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              className={classes.tabs}
              variant="scrollable"
              scrollButtons="on"
              aria-label="Scrollable tabs"
            >
              <Tab label="Obituary" value="obituary" />
              <Tab label="Program" value="program" />
              <Tab label="Contact" value="contact" />
              <Tab label="RSVP" value="rsvp" />
            </Tabs>
          </Stack> */}
          <div id="home">
            {/* <Typography
              variant="h1"
              component="h1"
              align="center"
              gutterBottom
              sx={{ fontFamily: "Dancing Script" }}
            >
              A Gathering to Celebrate<br></br>the Life and Memory of Cynthia
              <br></br>
              <img src="callalily.png" alt="calla lily" width="20%" />
            </Typography> */}

            {/* <Stack
              alignItems="center"
              sx={{ marginBottom: "2em", marginTop: "1em" }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: "200px",
                  height: "60px",
                  fontSize: "1.5rem",
                  marginBottom: "0.5em",
                  backgroundColor: "#9E1E38",
                }}
                onClick={handleClick}
              >
                RSVP Now
              </Button>
              <p style={{ fontSize: "0.8rem", textAlign: "center" }}>
                At 2:00 pm on Saturday May 13, 2023, we will be celebrating the
                life and memory of Cynthia Warsylewicz. We invite you to join us
                at the beautiful McKenzie Lake Beach Club. Please RSVP by April
                15, 2023.
              </p>
            </Stack> */}
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <img
                src="cynthia1.jpg"
                alt="Cynthia Warsylewicz"
                style={{ width: "100%" }}
              />
            </Box>
            <StyledHr />
          </div>

          <div id="obituary">
            <Stack
              alignItems="center"
              sx={{ marginBottom: "2em", marginTop: "1em" }}
            >
              <Typography
                variant="h2"
                component="h2"
                align="center"
                gutterBottom
                sx={{ fontFamily: "Dancing Script" }}
              >
                Cynthia Warsylewicz
              </Typography>

              <p style={{ fontSize: "0.8rem" }}>
                September 14, 1947 - December 12, 2022
              </p>
            </Stack>
            <p>
              Cynthia was born on September 14, 1947, in Yorkton, Saskatchewan.
              Cynthia was one of the 10 children of Peter and Pearl Yanish,
              namely Patricia, Peter, Eleanor, Grace, Sylvia, Ronald, Cynthia,
              Margaret, Gerry, and Jaci.
            </p>
            <p>
              Cynthia married Barry Warsylewicz on November 9, 1968. For the
              next 54 years they grew a deep and loving relationship and were
              inseparable. They had two children Aaron and Sarah.
            </p>
            <p>
              Cynthia attended both separate and public schools in Yorkton and
              graduated High School in 1965. Cynthia said there were only two
              career choices for women at that time: secretary or nurse. Cynthia
              chose nursing following in the footsteps of her older sisters
              Grace and Sylvia. Cynthia graduated from The Regina General
              Hospital School of Nursing from a 3-year diploma program in 1968.
              Cynthia also took University classes majoring in Psychology, and
              as life and location got in her way, fell short a couple of
              classes to complete her B.A.
            </p>
            <p>
              Cynthia practiced nursing at Regina General, Saskatoon City,
              Stanton Yellowknife, and Porcupine Plain Regional Hospital.
              Wherever Cynthia worked she was highly respected. It was working
              in rural hospitals where she truly thrived, saying "You never know
              what will come trough the door". This background in nursing along
              with determination and compassion proved very useful in caring for
              daughter Sarah after she was severely hurt in a motor vehicle
              accident in 1995. When Sarah was brought back to her home in
              Greenwater Lake Provincial Park, Cynthia attended to her physical,
              medical, spiritual, and social needs for nearly 30 years. In 2014,
              the family moved to Calgary to be closer to son Aaron who was
              already there teaching IT. In 2016, Sarah obtained residency in
              URSA Copperfield Group Home allowing Cynthia more time to pursue
              personal interests.
            </p>
            <p>
              Cynthia enjoyed gardening, hiking, fishing, cross country skiing,
              camping, travel, and reading. While residing in Greenwater Lake
              Provincial Park, she was active on the Porcupine Plain Library
              board and a member of the local Beta Sorority. In Calgary she
              became quite accomplished in Tai Chi and was a member of a local
              chapter. Cynthia has always loved frequent family get-togethers,
              and more recently, she enjoyed riding her e-bike on trails in Fish
              Creek.
            </p>
            <Paper
              className={classes.quote}
              sx={{
                marginLeft: 5,
                marginRight: 5,
                backgroundColor: "#800020",
              }}
            >
              <Typography variant="subtitle1" className={classes.quoteText}>
                "For what is it to die but to stand naked in the wind and to
                melt into the sun?"<br></br>- Kahlil Gibran
              </Typography>
            </Paper>
            <Stack
              alignItems="center"
              sx={{ marginBottom: "1em", marginTop: "1em" }}
            >
              <Carousel
                indicatorIconButtonProps={{
                  style: { display: "none" },
                }}
                indicatorContainerProps={{
                  style: { display: "none" },
                }}
                sx={{ width: "100%", textAlign: "center", height: 800 }}
              >
                {images.map((image) => (
                  <StyledImage key={image} src={image} alt="carousel item" />
                ))}
              </Carousel>
            </Stack>
            <StyledHr />
          </div>
          {/* 
          <div id="program">
            <Stack
              alignItems="center"
              sx={{ marginBottom: "2em", marginTop: "1em" }}
            >
              <Typography
                variant="h2"
                component="h2"
                align="center"
                gutterBottom
                sx={{ fontFamily: "Dancing Script" }}
              >
                Program Details
              </Typography>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h3">
                  <EventNoteIcon /> Date
                </Typography>
                <Typography variant="body1">May 13, 2023</Typography>
                <br></br>
                <Typography variant="h3">
                  <TimeIcon></TimeIcon> Time
                </Typography>
                <Typography variant="body1">
                  Program begins at 2:00 pm.
                </Typography>
                <Typography variant="body1">
                  Time will be available for anyone wanting to share memories or
                  anecdotes after the short scheduled program.
                </Typography>
                <Typography variant="body1">Light lunch to follow.</Typography>
                <br></br>
                <Typography variant="h3">
                  <SendIcon></SendIcon> RSVP
                </Typography>
                <Typography variant="body1">
                  Please complete the RSVP form by April 15, 2023.
                </Typography>
                <br></br>
                <Typography variant="h3">
                  <LocationOnIcon></LocationOnIcon> Location
                </Typography>
                <Typography variant="body1">
                  McKenzie Lake Beach Club
                </Typography>
                <Typography variant="body1">
                  16199 McKenzie Lake Way SE
                </Typography>
                <Typography variant="body1">
                  Calgary, Alberta T2Z 1L7
                </Typography>
                <br></br>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <img
                    src="lake.jpg"
                    alt="McKenzie Lake"
                    style={{ width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>

            <div className={classes.iframeContainer}>
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2515.8728825763233!2d-113.98502278410702!3d50.907568162202274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537177c82cc7ba85%3A0x28b628871d9fe7a4!2sMckenzie%20Lake%20Beach%20Club!5e0!3m2!1sen!2sca!4v1676772511914!5m2!1sen!2sca"
                width="95%"
                height="450px"
              ></iframe>
            </div>

            <StyledHr />
          </div>
          <div id="contact">
            <Stack
              alignItems="center"
              sx={{ marginBottom: "2em", marginTop: "1em" }}
            >
              <Typography
                variant="h2"
                component="h2"
                align="center"
                gutterBottom
                sx={{ fontFamily: "Dancing Script" }}
              >
                Contact and Hotel Information
              </Typography>
            </Stack>
            <Typography variant="h3">
              <HelpIcon></HelpIcon> Contact
            </Typography>
            <Typography variant="body1">Barry Warsylewicz</Typography>
            <Typography variant="body1">
              Phone: <Link href="tel:+1-587-585-9129">587-585-9129</Link>
            </Typography>
            <Typography variant="body1">
              Email:{" "}
              <Link href="mailto:bwarsylewicz@gmail.com">
                bwarsylewicz@gmail.com
              </Link>
            </Typography>
            <br></br>
            <Typography variant="h3">
              <HotelIcon></HotelIcon> Nearest Hotel
            </Typography>
            <Typography variant="body1">
              Courtyard by Marriott Calgary South
            </Typography>
            <Typography variant="body1">
              3750 Market St SE, Calgary, AB T3M 2P2
            </Typography>
            <Typography variant="body1">
              <Link
                href="https://www.marriott.com/en-us/hotels/yycdc-delta-hotels-calgary-south/overview/"
                target="_blank"
                rel="noreferrer"
              >
                Hotel Website
              </Link>
            </Typography>

            <StyledHr />
          </div>
          <div id="rsvp">
            <Stack
              alignItems="center"
              sx={{ marginBottom: "2em", marginTop: "1em" }}
            >
              <Typography
                variant="h2"
                component="h2"
                align="center"
                gutterBottom
                sx={{ fontFamily: "Dancing Script" }}
              >
                RSVP
              </Typography>
            </Stack>
            <div className={classes.iframeContainer}>
              <iframe
                title="Google Form"
                src="https://docs.google.com/forms/d/e/1FAIpQLScoawPrtgIWo-OPFHVWKjWTXBEdSaWIAI7HrbVXAK8sjLQpGg/viewform?embedded=true"
                width="100%"
                height="2000px"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >
                Loading…
              </iframe>
            </div>
          </div> */}
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
